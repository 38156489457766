<template>
  <div>
    <transition-group name="fade" tag="div">
      <div v-for="deposit in deposits" :key="deposit.id" class="deposit">
        <div class="item">
          <div v-if="deposit.method" class="icon">
            <img
              v-if="deposit.method === 'qiwi'"
              :alt="deposit.method"
              src="@/assets/icons/profile/qiwi.png"
            />
            <img
              v-else-if="deposit.method === 'piastrix'"
              :alt="deposit.method"
              src="@/assets/icons/profile/piastrix.png"
            />
            <img
              v-else-if="deposit.method === 'freekassa'"
              :alt="deposit.method"
              src="@/assets/icons/profile/fkwallet.png"
            />
          </div>
          <div class="info">
            <div class="title">{{ deposit.method }}</div>
            <div class="desc second">#{{ deposit.id }}</div>
          </div>
        </div>
        <div class="item second">
          {{ deposit.created_at | formatDate("yyyy-MM-dd") }}
        </div>
        <div class="item amount">
          {{ convertAmount(deposit.amount, deposit.currency) }}
          {{ icons[deposit.currency] }}
        </div>
        <div class="item status">
          <button v-if="deposit.status == 0" class="waiting">В ожидании</button>
          <button v-else-if="deposit.status == 2" class="danger">Ошибка</button>
          <button v-else-if="deposit.status == 1" class="success">
            Успешно
          </button>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { format } from "date-fns";
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      deposits: [],
    };
  },
  computed: {
    ...mapState("user", ["icons"]),
    ...mapGetters("user", ["convertAmount"]),
  },
  async created() {
    try {
      let { data } = await this.$http.get("wallet/deposit/history");
      this.deposits = data.response;
    } catch (error) {
      this.$toast.error(error?.message || "Ошибка отправки запроса");
    }
  },
  filters: {
    formatDate(value, type) {
      return format(new Date(value.replace(" ", "T")), type);
    },
  },
};
</script>

<style lang="scss" scoped>
.deposit {
  border-top: 1px solid #f5f6fa;
  display: flex;
  align-items: center;
  .item {
    width: calc(100% / 4);
    display: flex;
    align-items: center;
    padding: 15px 0;
    &:last-child {
      justify-content: flex-end;
    }
    button {
      border-radius: 6px;
      display: block;
      color: white;
      padding: 15px 25px;
      //   font-weight: bold;
      font-size: 15px;
      &.success {
        background: #57c400;
      }
      &.danger {
        background: #ef4141;
      }
      &.waiting {
        background: #f4ca32;
      }
    }
    .icon {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      background: #f4f6f9;
      margin-right: 15px;
      border-radius: 6px;
      justify-content: center;
      img {
        width: 25px;
      }
    }
    .info {
      .title {
        // font-weight: 500;
        font-size: 20px;
        margin-bottom: 3px;
        text-transform: capitalize;
      }
    }
    &.amount {
      font-weight: 500;
      font-size: 17px;
      color: black;
    }
    .second,
    &.second {
      //   font-weight: 500;
      font-size: 15px;
      color: #a3afc9;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    .item {
      width: calc(100%);
      &:last-child {
        justify-content: unset;
      }
    }
  }
}
</style>
